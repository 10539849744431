<template>
  <modal
    v-if="author"
    title="Написать партнеру"
    mainClass="popup-write-partner"
    wrapClass="popup-write-partner__wrap"
    contentClass="popup__content popup-write-partner__content"
    :show="show"
    @hide="$emit('hide')"
  >
    <div class="popup-write-partner__item">
      <p class="popup-write-partner__text">
        Скопируйте текст из формы справа и отправьте в Директ вашему партнеру.
      </p>
      <!-- Желтый варнинг, уже не актуален, но может пригодиться для другого текста (пригодился :) ) -->
      <p
        class="popup-write-partner__text popup-write-partner__text&#45;&#45;bg"
        v-if="!author.blogger_profile.theses"
      >
        У вас нету тезисов, вы можете добавить их в сообщение самостоятельно
      </p>
    </div>
    <div class="popup-write-partner__item">
      <div class="popup-write-partner__item-wrap">
        <ul class="popup-write-partner__text">
          <li class="popup-write-partner__text-item">ВП на завтра ({{ date }})</li>
          <li class="popup-write-partner__text-item">Формат рекламы: Сторис 45 секунд</li>
        </ul>
        <ul class="popup-write-partner__text" v-if="!!author.blogger_profile.theses">
          <li class="popup-write-partner__text-item">Тезисы:</li>
          <li class="popup-write-partner__text-item theses">
            <pre>{{ author.blogger_profile.theses }}</pre>
          </li>
        </ul>
      </div>
      <button
        type="button"
        v-clipboard:copy="text"
        v-clipboard:success="copyAndSend"
        class="btn--orange"
      >
        Скопировать и написать партнеру
      </button>
      <a :id="linkId" :href="linkSrc" target="_blank" />
    </div>
  </modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';
import moment from 'moment';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    author: Object,
    partner: Object,
  },
  components: {
    Modal,
  },
  computed: {
    date() {
      const currentDate = moment(Date.now());
      currentDate.add(1, 'days');
      return currentDate.format('DD.MM.YYYY');
    },
    linkId() {
      return `send_link_${this.partner.id}`;
    },
    linkSrc() {
      return `https://www.instagram.com/${this.partner.blogger_profile.instaname}/`;
    },
    text() {
      return `
    ВП на завтра (${this.date})
    Формат рекламы: Сторис 45 секунд

    Тезисы:
${this.author.blogger_profile.theses}`;
    },
  },
  methods: {
    copyAndSend() {
      document.getElementById(this.linkId).click();
    },
  },
};
</script>

<style scoped>
.btn--orange {
  max-width: none;
  padding: 10px;
}
.theses {
  overflow: auto;
}
.theses > pre {
  font-family: Rubik;
}
</style>
