import apiService from '@main/api/index';
import { URL_PREFIX } from './index';

const SIMULATION_CREATE = `${URL_PREFIX}/api/sim/`;

export async function CreateSimulation(data) {
  try {
    const response = await apiService.post('api/sim/create/', data);
    return response;
  } catch (error) {
    return { error };
  }
}

/**
 * Запуск симуляции
 * @param {string} mode Режим симуляции
 * @param {number} accountId Идентификатор аккаунта
 */
export async function RunSim(mode, accountId) {
  try {
    const response = await apiService.post(SIMULATION_CREATE, { mode, account_id: accountId });
    return response;
  } catch (error) {
    return {
      error,
    };
  }
}
